<template>
  <div>
    <div
      class="hero-image"
      style="
        margin-top: -1.5rem;
        background-image: url('/images/banners/search-header.jpg');
        height: 200px;
        z-index: 9 !important;
      "
    >
      <div class="hero-text" style="background-color: transparent">
        <div class="row">
          <div class="col-10 mx-auto text-left">
            <label for class="text-white font-weight-bold am-btn-blue p-2"
              >Search by keyword</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              v-model="searchTerm"
              @input="search"
              style="
                background-color: rgba(255, 255, 255, 0.9);
                height: 3rem;
                line-height: 3rem;
                border-color: #9dc7de;
                width: 100%;
              "
            />

            <div
              id="blogSearchResultsBox"
              v-if="blogPosts.length > 0"
              style="
                position: absolute;
                top: 5rem;
                left: 0;
                right: 0;
                background-color: #fff;
                width: 100%;
              "
            >
              <div
                class="container"
                id="blogSearchResults"
                v-if="blogPosts.length > 0"
              >
                <div v-for="p in blogPosts">
                  <router-link :to="'/' + p.category.slug + '/' + p.slug">
                    <div
                      class="row py-3"
                      style="border-bottom: 1px solid #8dc553"
                    >
                      <div class="col am-text-blue font-weight-bold">
                        {{ p.title }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="z-index: 1; background: #9dc7de">
      <div class="container">
        <div class="row py-3">
          <div class="col-md-3 text-center nav-col mb-3 mb-md-0">
            <span>
              <a href="#hormones" class="h4 text-white">Hormones</a>
            </span>
          </div>
          <div class="col-md-3 text-center nav-col mb-3 mb-md-0">
            <span>
              <a href="#gut-health" class="h4 text-white">Gut Health</a>
            </span>
          </div>
          <div class="col-md-3 text-center nav-col mb-3 mb-md-0">
            <span>
              <a href="#genetics-dna" class="h4 text-white">Genetics & DNA</a>
            </span>
          </div>
          <div class="col-md-3 text-center nav-col">
            <span>
              <a href="#detox-fasting" class="h4 text-white">Detox & Fasting</a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="container mt-5">
      <div class="row mb-3">
        <div class="col">
          <span class="h4 text-dark d-block mb-3">Most popular posts</span>
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in popular">
              <div class="card border-0" style="background-color: #ccc">
                <div class="card-body py-3">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <span class="h6 text-dark">{{ post.title | max35Chars }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container mt-5 mb-5">
      <div class="row mb-3" id="latest-posts">
        <div class="col">
          <span class="h3 text-dark d-block mb-2">Latest posts</span>
          <hr class="pb-3" />
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in popular">
              <div class="card border-0">
                <div class="card-body p-0">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <img
                      :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                      :alt="post.title"
                      width="900"
                      height="450"
                      class="img-fluid"
                    />
                  </router-link>
                  <span
                    class="h5 d-block pt-3 am-text-blue"
                    style="height: 60px"
                    >{{ post.title | max35Chars }}</span
                  >
                  <router-link
                    :to="post.category.slug + '/' + post.slug"
                    class="text-dark h4 font-weight-bold"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" id="hormones">
        <div class="col">
          <span class="h3 text-dark d-block mb-2">Hormones</span>
          <hr class="pb-3" />
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in hormones">
              <div class="card border-0">
                <div class="card-body p-0">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <img
                      :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                      :alt="post.title"
                      width="900"
                      height="450"
                      class="img-fluid"
                    />
                  </router-link>
                  <span
                    class="h5 d-block pt-3 am-text-blue"
                    style="height: 60px"
                    >{{ post.title | max35Chars }}</span
                  >
                  <router-link
                    :to="post.category.slug + '/' + post.slug"
                    class="text-dark h4 font-weight-bold"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <router-link to="/hormones" class="btn am-btn-blue"
                >View All Hormone Posts</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" id="gut-health">
        <div class="col">
          <span class="h3 text-dark d-block mb-2">Gut Health</span>
          <hr class="pb-3" />
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in gutHealth">
              <div class="card border-0">
                <div class="card-body p-0">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <img
                      :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                      :alt="post.title"
                      width="900"
                      height="450"
                      class="img-fluid"
                    />
                  </router-link>
                  <span
                    class="h5 d-block pt-3 am-text-blue"
                    style="height: 60px"
                    >{{ post.title | max35Chars }}</span
                  >
                  <router-link
                    :to="post.category.slug + '/' + post.slug"
                    class="text-dark h4 font-weight-bold"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <router-link to="/healthy-gut" class="btn am-btn-blue"
                >View All Gut Health Posts</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" id="genetics-dna">
        <div class="col">
          <span class="h3 text-dark d-block mb-2">Genetics &amp; DNA</span>
          <hr class="pb-3" />
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in genetics">
              <div class="card border-0">
                <div class="card-body p-0">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <img
                      :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                      :alt="post.title"
                      width="900"
                      height="450"
                      class="img-fluid"
                    />
                  </router-link>
                  <span
                    class="h5 d-block pt-3 am-text-blue"
                    style="height: 60px"
                    >{{ post.title | max35Chars }}</span
                  >
                  <router-link
                    :to="post.category.slug + '/' + post.slug"
                    class="text-dark h4 font-weight-bold"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <router-link to="/genetics-dna" class="btn am-btn-blue"
                >View All Genetics &amp; DNA Posts</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" id="detox-fasting">
        <div class="col">
          <span class="h3 text-dark d-block mb-2">Detox &amp; Fasting</span>
          <hr class="pb-3" />
          <div class="row">
            <div class="col-md-4 mb-3" v-for="post in detox">
              <div class="card border-0">
                <div class="card-body p-0">
                  <router-link :to="post.category.slug + '/' + post.slug">
                    <img
                      :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                      :alt="post.title"
                      width="900"
                      height="450"
                      class="img-fluid"
                    />
                  </router-link>
                  <span
                    class="h5 d-block pt-3 am-text-blue"
                    style="height: 60px"
                    >{{ post.title | max35Chars }}</span
                  >
                  <router-link
                    :to="post.category.slug + '/' + post.slug"
                    class="text-dark h4 font-weight-bold"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <router-link to="/detox-and-cleanse" class="btn am-btn-blue"
                >View All Detox &amp; Fasting Posts</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="container mb-5">
      <div class="row">
        <div class="col">
          <span class="h4 text-dark d-block mb-3">Latest posts</span>
          <div class="row">
            <div class="col">
              <router-link
                v-for="post in popular"
                :to="post.category.slug + '/' + post.slug"
                class="btn btn-secondary mr-2 mb-3"
              >{{ post.title | max35Chars }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      blogPosts: [],
      searchTerm: "",
      //
      popular: [],
      hormones: [],
      gutHealth: [],
      genetics: [],
      detox: [],
    };
  },

  methods: {
    fetchIndexPosts() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/blog/index")
        .then(({ data }) => {
          this.popular = data.popular;
          this.hormones = data.hormones;
          this.gutHealth = data.gutHealth;
          this.genetics = data.genetics;
          this.detox = data.detox;
        });
    },
    search() {
      if (this.searchTerm.length > 1) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/blog/search", {
            search_term: this.searchTerm,
          })
          .then(({ data }) => {
            this.blogPosts = data;
            console.log(data);
          });
      }
    },
  },

  mounted() {
    this.fetchIndexPosts();
  },
  filters: {
    max35Chars(title) {
      return title;
    },
  },
};
</script>

<style>
.am-text-blue {
  color: #9dc7de !important;
}

.am-btn-blue {
  background: #9dc7de !important;
  color: white !important;
}
</style>